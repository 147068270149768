.img {
  width: 13%;
  display: block;
  margin: auto;
  border-radius: 50%;
  /* max-width: 150px; */
  transform: translate(0px, -20vh);
}
.img2 {
  width: 13%;
  display: block;
  margin: auto;
  border-radius: 50%;
  /* max-width: 150px; */
  transform: translate(0px, -15vh);
}
.pro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  flex-direction: column;
}
.name {
  transform: translate(0vh, -50vh);
  color: #e5e5e5;
  font-style: italic;
}
.score {
  transform: translate(0vh, -50vh);
  color: #e5e5e5;
  font-style: italic;
}

.name2 {
  transform: translate(0vh, -40vh);
  color: #e5e5e5;
  font-style: italic;
}
.score2 {
  transform: translate(0vh, -43vh);
  color: #e5e5e5;
  font-style: italic;
}

.i {
  transform: translate(0vh, -42vh);
}
.b {
  transform: translate(0vh, -42vh);
  background-color: wheat;
}
