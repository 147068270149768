/* Navbar */

.navbar {
  height: 7vh;
  width: 100%;
  background: #ffc300;
  color: var(--colors-text);
  /* position: absolute; */
  top: 0;
  padding: 0 8vw;
  font-weight: bold;
}

.nav-user {
  display: flex;
}

.uln {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 90%;
}

.navimg {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transform: translate(-20px, 5px);
}

/* Buttons */

.goog {
  padding: 0.5rem 1rem;
  border: 2px solid #e07a5f;
  border-radius: 5px;
  color: #e07a5f;
  background-color: #3d405b;
  cursor: pointer;
  font-size: 1.4em;
  transition: 0.5s;
  margin-left: auto;
}
.goog:hover {
  border-color: #81b29a;
  box-shadow: 0 0.5em 0.5em 0em #81b29a;
  color: #f4f1de;
  transform: translate(-0.05em, -0.1em);
}

.btn,
button {
  background-color: var(--color-gray);
  border: none;
  color: var(--color-text);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0.5rem 1rem 0.5rem 0;
}

@media only screen and (max-width: 768px) {
  button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}

button:hover {
  filter: brightness(90%);
}

button:disabled,
button[disabled] {
  filter: brightness(80%);
  cursor: not-allowed;
}

button.btn-blue {
  background-color: var(--color-blue);
  color: white;
}

button.btn-red {
  background-color: var(--color-red);
  color: white;
}

button.btn-green {
  background-color: var(--color-green);
  color: white;
}

button.btn-google {
  background-color: white;
  color: var(--color-text);
}

.btn-google img {
  width: 30px;
  margin-right: 10px;
}

button.btn-logo {
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
}
