textarea {
  background-color: #0a939626;
  color: #e9d8a6;
  font-size: 1.5rem;
  border-radius: 10px;
  padding: 1em;
  border: 2px solid transparent;
  outline: none;
  line-height: 1.4;
  width: 70%;
  height: 70vh;
  transition: all 0.2s;
}
form {
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
textarea:hover {
  background-color: #0a93968c;
}

textarea:focus {
  background-color: #0a9396a6;
}

button {
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
}
