Canvas {
  height: 93vh;
}
#CP {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #219ebc;
  font-size: 50px;
  width: 100%;
  height: 10vh;
  font-family: "Orbitron", sans-serif;
}
#CountDown {
  position: absolute;
  color: #219ebc;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150%;
  font-family: "Orbitron", sans-serif;
}

#timer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 50px;
  width: 100%;
  height: 170%;
  font-family: "Orbitron", sans-serif;
}
